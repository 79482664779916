<template>

  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="12"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title float-left pr-1 mb-0">
              Create Roster Group
            </h1>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <validation-observer
      ref="rosterGroupCreateForm"
      #default="{invalid}"
    >
      <b-form @submit.prevent="submitForm">
        <b-card>
          <b-row>
            <b-col cols="md-6">
              <b-form-group
                label="Group Name*"
                label-for="h-roster-group-name"
                label-cols-md="3"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Name"
                  vid="name"
                  rules="required"
                >
                  <b-form-input
                    id="h-roster-group-name"
                    v-model="name"
                    placeholder="Name"
                    :state="(errors.length > 0 || nameValidation) ? false : null"
                    name="name"
                    @input="nameValidation == true ? nameValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="nameValidation"
                    class="text-danger"
                  >
                    {{ nameError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="md-6" />

            <b-col cols="md-6">
              <b-form-group
                label="Abbreviation*"
                label-for="h-roster-group-abbreviation"
                label-cols-md="3"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Abbreviation"
                  vid="abbreviation"
                  rules="required"
                >
                  <b-form-input
                    id="h-roster-group-abbreviation"
                    v-model="abbreviation"
                    placeholder="Abbreviation"
                    :state="(errors.length > 0 || abbreviationValidation) ? false : null"
                    name="abbreviation"
                    @input="abbreviationValidation == true ? abbreviationValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="abbreviationValidation"
                    class="text-danger"
                  >
                    {{ abbreviationError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="md-6" />

            <b-col cols="md-9">
              <b-form-group
                label="Day Off*"
                label-for="h-roster-group-dayOffDays"
                label-cols-md="2"
                class=""
              >
                <validation-provider
                  #default="{ errors }"
                  name="Day Off"
                  vid="dayOffDays"
                  rules="required"
                >
                  <b-form-checkbox-group
                    id="h-roster-group-dayOffDays"
                    v-model="dayOffDays"
                    button-variant="outline-primary"
                    buttons
                    class="spaceBetweenRadio"
                    multiple
                  >
                    <b-form-checkbox
                      v-for="(day, kindex) in optionsChekcbox"
                      :key="kindex"
                      :value="day.value"
                      class="mb-50 customCheckbox"
                    >
                      <feather-icon
                        v-if="dayOffDays.includes(day.value)"
                        icon="CheckIcon"
                        class="mr-50"
                        size="16"
                      />
                      <span class="align-middle">{{ day.text }}</span>
                    </b-form-checkbox>
                  </b-form-checkbox-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="dayOffDaysValidation"
                    class="text-danger"
                  >
                    {{ dayOffDaysError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="md-9">
              <b-form-group
                label="Remarks"
                label-for="h-event-remarks"
                label-cols-md="2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Remarks"
                  vid="remarks"
                  rules=""
                >
                  <b-form-textarea
                    id="h-event-remarks"
                    v-model="remarks"
                    placeholder="Remarks"
                    :state="(errors.length > 0 || remarksValidation) ? false : null"
                    name="remarks"
                    @input="remarksValidation == true ? remarksValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="remarksValidation"
                    class="text-danger"
                  >
                    {{ remarksError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="md-6">
              <validation-provider
                #default="{ errors }"
                name="Status"
                vid="status"
                rules="required"
              >
                <b-form-group
                  label="Status*"
                  label-for="h-roster-group-status"
                  label-cols-md="3"
                  :state="(errors.length > 0 || statusValidation) ? false : null"
                >
                  <v-select
                    id="h-roster-group-status"
                    v-model="status"
                    label="title"
                    :options="option"
                    :reduce="title => title.code"
                    :clearable="false"
                    @input="statusValidation == true ? statusValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="statusValidation"
                    class="text-danger"
                  >
                    {{ statusError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="md-6" />
          </b-row>
        </b-card>
        <b-nav class="wrap-border save-nav">
          <li
            class="nav-item ml-auto"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              :to="{ name: 'operation-roster-groups-index' }"
            >
              Cancel
            </b-button>
          </li>
          <li
            class="nav-item mr-1"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              :disabled="invalid"
            >
              <feather-icon
                icon="SaveIcon"
                class="mr-50"
              />
              <span class="align-middle">Save Roster Group</span>
            </b-button>
          </li>
        </b-nav>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BFormCheckbox, BFormCheckboxGroup, BNav, BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import {
  // eslint-disable-next-line import/named
  required, maxValue, minValue, numeric, between,
} from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-flatpicker.scss'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormCheckboxGroup,
    BForm,
    BButton,
    BNav,
    BFormTextarea,

    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      name: '',
      abbreviation: '',
      remarks: '',
      dayOffDays: [],
      status: 'active',
      nameError: 'Valid date period is required',
      nameValidation: false,
      abbreviationError: 'Valid operating hours is required',
      abbreviationValidation: false,
      remarksError: 'Valid operating hours is required',
      remarksValidation: false,
      dayOffDaysError: 'Valid applicable days is required',
      dayOffDaysValidation: false,
      statusError: 'Valid status is required',
      statusValidation: false,
      option: [{ title: 'Active', code: 'active' }, { title: 'Inactive', code: 'inactive' }],
      optionsChekcbox: [
        { text: 'Mon', value: 'Mon' },
        { text: 'Tue', value: 'Tue' },
        { text: 'Wed', value: 'Wed' },
        { text: 'Thu', value: 'Thu' },
        { text: 'Fri', value: 'Fri' },
        { text: 'Sat', value: 'Sat' },
        { text: 'Sun', value: 'Sun' },
      ],

      // validation rules
      required,
      numeric,
      maxValue,
      minValue,
      between,
    }
  },
  methods: {
    submitForm() {
      this.$refs.rosterGroupCreateForm.validate().then(success => {
        if (success) {
          const formData = new FormData()

          formData.append('name', this.name)
          formData.append('abbreviation', this.abbreviation)
          formData.append('remarks', this.remarks)
          formData.append('dayOffDays', this.dayOffDays)
          formData.append('status', this.status)

          this.$http.post('operation/roster-groups/store', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              if (response.data.data) {
                this.$swal({
                  title: 'Roster Group Created.',
                  html: response.data.message || '',
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/icons/save.png'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  showCancelButton: false,
                  allowOutsideClick: false,
                  confirmButtonText: 'Okay',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
                  .then(result => {
                    if (result.value) {
                      this.$router.push({ name: 'operation-roster-groups-index' })
                    }
                  })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message || '',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'name') {
                    this.nameError = validationError.msg
                    this.nameValidation = true
                  } else if (validationError.param === 'status') {
                    this.statusError = validationError.msg
                    this.statusValidation = true
                  } else if (validationError.param === 'abbreviation') {
                    this.abbreviationError = validationError.msg
                    this.abbreviationValidation = true
                  } else if (validationError.param === 'remarks') {
                    this.remarksError = validationError.msg
                    this.remarksValidation = true
                  } else if (validationError.param === 'dayOffDays') {
                    this.dayOffDaysError = validationError.msg
                    this.dayOffDaysValidation = true
                  } else {
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                      },
                    })
                  }
                })

                const items = document.getElementsByClassName('text-danger')
                window.scrollTo({
                  top: items[0].offsetTop,
                  behavior: 'smooth',
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
  },
}
</script>

<style scoped>
  .customCheckbox {
    border-right-width: 1px !important;
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
    border-left-width: 1px !important;
  }

  .btn-group-toggle [class*=btn-outline-]:not(:last-child) {
    border-right-width: 1px !important;
  }

  .btn-group, .btn-group-vertical {
    position: relative;
    display: contents;
    vertical-align: middle;
  }
</style>
